.blur_all {
    backdrop-filter: blur(2px);
    background:rgba(0,0,0, 0.12);
    
    position: fixed;
    bottom: 0px;
    z-index: 1;
  
    width: 100%;
    height: 100%;
}

.thank_you_popup {
    z-index: 1;
}


        .overlay_icon_and_headline_container .material-icons{
            margin-left: 8px;
        }

        .overlay_icon_and_headline_container_placeholder{
            width: 32px;
            height: 10px;
        }

    .overlay_text_container {
        width: 90%;
        margin-left: 5%;
    }

    .overlay_img {
        max-width: 30%;
        height: auto;
        position: relative;
    }


    .overlay_buttons_container {
        position: relative;
        width: 90%;
        left: 5%;
    }


@media only screen and (min-width: 600px) {

    .overlay_img {
        max-width: 20%;
    }
}