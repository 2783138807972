.intro_img_container {
    display: flex;
    width: 100%;
    height: 240px;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
}

.intro_img {
    max-width: 75%;
    max-height: 240px;
}


.skip_button {
    position: static;
    
    width: 100%;
    z-index: 10;
    bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*
.skip_button {
    position: fixed;
    width: 100%;
    z-index: 10;
    bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
*/
