/* financial accounts containers */
    
.narrow_linked_account_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    margin: 8px 0px;

}

.regular_linked_account_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin: 8px 0px;


}

.linked_account_headline {
    width: 100%;
    text-align: left;
}

.linked_account_headline p{
    margin: 16px 0 0 0;
}

.linked_account_headline h2{
    margin: 16px 0 0 0;
}

.financial_account {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;

    width: 100%;

    display: flex;
    align-items: center;
    margin: 8px auto;
    box-sizing: border-box;    
}

.financial_account .image_container {
    flex-basis: 20%;
    text-align: center;
}

.financial_account .image_container img {
    width: 25px;
    height: 25px;
    
}

.financial_text_container {
    flex-basis: 45%;
    text-align: left;
    padding: 4px 0px;
}

.financial_text_container p{
    padding: 0.25rem 0px;
    margin: 0px;
}

.account_fees {
    flex-basis: 15%;
    text-align: left;
}

.check_mark .image_container {
    flex-basis: 10%;
}

.util_container_financial {
    display: flex;
    flex-direction: column;
    align-items: center;
}