.add_custom_goal {
  display: flex;
  align-items: center;
  width: fit-content;

  padding-right: 16px;

  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 12%);
  background: #ffffff;
  cursor: pointer;

  margin-top: 24px;
}



@media only screen and (min-width: 600px) {  
  .add_custom_goal:hover {
    background: #B5F1DB;
  }
}


