
    .goals {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .plan_bottom .headline {
        text-align: center;
        width: 100%;
    }
       
    
    /* goal takeaway */
        .goal_takeaway_container {
            display: flex;
            justify-content: center;
            width: 85%;
            /*border-bottom: 1px solid rgba(0, 0, 0, 0.12);*/
        }

        
    /* headline for linked accounts */

    .linked_account_headline {
            width: 85%;
    }