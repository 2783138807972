
.card {
    display: block;
    border: solid 1px rgba(0,0,0,0.12);
    border-radius: 5px;
    background-color: white;
    
    margin: 8px auto;
    padding: 16px 8px;
    /*width: 100%;*/
}

.small_card_in_carousel {
    height: calc(100% - 32px - 8px);    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 110px;
}

.card img{
    width: fit-content;
}
.small_card_in_carousel img {
    max-width: 100px;
}

.small_card_in_carousel:hover, a.small_card_in_carousel:hover  {
    transform: translate(0,-6px);
    box-shadow: 0 4px 4px 0 rgb(0,0,0,0.25);
    transition: transform .2s,-webkit-transform .2s;
}

/************** List Card with a Header  *******************/
.List_card_w_header_container {
    border-radius: 5px;
    border: solid 1px rgba(0,0,0,0.12);
    background-color: white;
}

.List_card_w_header_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px rgba(0,0,0,0.12);
    padding: 0 8px;
}

.List_card_w_header_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
}

.List_card_w_header img {
    max-width: 50px;
    max-height: 50px;
    margin: 8px 8px 8px 0;
}

.List_card_w_header_item_left {
    display: flex;
    align-items: center;
}

/**  offer cards  **/

.offer_card_w_img img {
    max-width: 135px;
    max-height: 100px;
    margin-top: 16px;
}

.offer_card_w_img {
    display: flex;
    flex-direction: column;
    
    width: 100%;

    justify-content: center;
    align-items: center;

    margin: 8px 0;

    border: solid 1px rgba(0,0,0,0.12);
    border-radius: 5px;
    background-color: white;
}
/*
.home_plans_carousel .offer_card_w_img {
    width: 85%;
}
*/

.carousel_slide .offer_card_w_img {
    margin: 0;
    height: 99%;
}

.offer_card_w_img p {
    margin: 16px 16px;
}

.offer_card_w_img_txt {
    text-align: center;
    margin-bottom: 8px;
}