/*
.articles_container {
    margin-top: 20px;
}*/

.articles_wrapper {
    margin: 24px 0;
}
.articles_headline {
    text-align: center;
    text-transform: capitalize;
}

@media only screen and (min-width: 600px) {
    .articles_container {
        width: 80%;
        left: 10%;
        position: relative;
    }
}