/************** dictionary term  *******************/

.dictionary_term_container {
    padding: 16px;
}

.dictionary_term_container img{
    max-width: 150px;
    max-height: 150px;
    margin-top: 16px;
}

.dictionary_term_container a {
    width: 100%;
}