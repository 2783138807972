@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.open_body {
    font-family: 'nunito', sans-serif;
    color: #28313B;
    background: #f9f9f9;
}

.gifting_page_main, .open_footer_wrapper {
    background: #fff;
    padding-top: 20px;
    
}

.about_gg_section {
    background: #f9f9f9;
    padding: 0 10%;
}

.header_buffer {
    height: 75px; 
    background: #fff;
}

@media only screen and (min-width: 600px) {
    .gifting_page_main, .open_footer_wrapper {
        padding-top: 40px;
    }
    .header_buffer {
        height: 80px;
        background: #fff;
    }
}



/* header */ 
header.gifting_page_header {
    background-color: white;
    
    width: 100%;
    position: fixed;
    top: 0%;
    z-index: 22;

    display: flex;
    align-items: center;
    justify-content: center;

    border-bottom: 1px solid #d8e1e9;
}

header .gifting_page_header_container {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 71px
}

.open_header_logo {
    background-color: transparent;
    
    height: auto;
    max-height: 65px;
}

.gifting_page_header_container div {
    flex-basis: 100%;
}

.gifting_page_header_container .button_container {
    text-align: right;
    justify-content: flex-end;
}


@media only screen and (min-width: 600px) {
    header.gifting_page_header {
        padding: 8px 0px;
    }

    .gifting_page_header_container div {
        flex-basis: 33%;
    }

}

/*  main  */


.gifting_page_h1 {
    padding: 0 16px;
    font-size: 26px;
    font-family: 'nunito';
    margin: 24px 0;
}



@media only screen and (min-width: 600px) {
    .gifting_page_h1 {
        padding: 0 200px;
        font-size: 48px;
    }
}


.gifting_page_columns_wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    justify-content: space-between;
}

@media only screen and (min-width: 600px) {
    
    .gifting_page_columns_wrapper {
        flex-direction: row;
        padding: 0 200px;
    }

    .gifting_page_columns_wrapper .left_column {
        flex-basis: 60%;
    }
    .gifting_page_columns_wrapper .right_column {
        flex-basis: 35%;
        
    }
}
    

.gifting_page_banner {
    width: 100%;
    border-radius: 5px;
}

.gifting_page_banner_placeholder{
    width: 50%;
    border-radius: 5px;
}

.welcome_message {
    line-height: 1.5;
}

.gifting_page_main_button_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.gifting_page_main_button_wrapper_child {
    margin-right: 24px;
    flex-basis: 60%;
}
.gifting_page_main_button_wrapper_child:last-child {
    margin-right: 0;
    flex-basis: 30%;
    flex-grow: 1;
}

/*  cards  */

.gifting_page_card {
    background-color: #F5F5F5;
    border: 1px solid #0000001f;
    border-radius: 5px;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 24px;
}
.gifting_page_card:first-of-type {
    margin-top: 0;
}

.gifting_page_card h2 {
    margin-top: 0;
    font-size: 1.5rem;
}

.gifting_page_card.yellow_card {
    background: #FFF9C0;
}

.gifting_page_card.green_card {
    background: #B5F1DB;
}

.gifting_page_card.red_card {
    background: #FFE3CE;
}

.gifting_page_card.blue_card {
    background: #DCF0FF;
}


.timeline_item {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 12px 0;
    border-bottom: 1px solid #0000001f;
}

.timeline_item:last-of-type {
    border-bottom: none;
}

.timeline_item_details p {
    margin: 8px 0;
}

.timeline_item_icon .material-icons-outlined{
    color: rgba(0,0,0,0.54);
    font-size: 1.5rem;
    padding: 0.6rem;
    background-color: #B5F1DB;
    border-radius: 100%;
    margin-right: 24px;
}

.button_wrapper {
    margin: 60px 12px;
}

@media only screen and (min-width: 600px) {
    .button_wrapper {
        margin: 60px 30%;
    }
}

.stripe_and_plaid_logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto;
}
.two_column_screenshot .right_side .stripe_and_plaid_logos img {
    flex: 1 1 40%;
    max-width: 40%;
    height: auto;
    margin: 8px;
    object-fit: contain;
}

.reviews_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.reviews_wrapper .review_container {
    margin: 24px;
    flex-basis: 27%;
}


.square_bottom_bg {
    background-image: url('../../../images/backgrounds/square_bottom.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom;
}