/* Button */

.button_container {
    position: fixed;
    width: 100%;
    z-index: 10;
    bottom: 6rem;

    display: flex;
    justify-content: center;
    align-items: center;
}


button {    
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0.875rem 1rem;
    margin: 0 auto;
    letter-spacing: 0.06rem;
    width: 90%;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    border: none;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12));
    text-decoration: none;
    background: #FFD700;
    color: rgba(0, 0, 0, 0.75);
    font-family: 'Nunito', sans-serif;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
}

button:hover
 {
    background: #FFA700;
}

button:focus {
    outline: 1px solid rgba(0, 0, 0, 0.12);
    outline-offset: -4px;
}

button:active {
    transform: scale(0.99);
}


.button_link {
    height: 100%;
    width: 100%;
    text-align: center;
 }

 button.inactive_button {

    background: rgba(158, 158, 158);
    cursor: not-allowed;
    color:rgba(0, 0, 0, 0.38)
 }

 button.inactive_button:hover {
    background: rgba(0, 0, 0, 0.38);
}

button.inactive_button:active {
    transform: scale(1);
}

@media only screen and (min-width: 600px) {

    /* smaller button on */
    button, .button {
        width: 50%;
     }
}

.button_w_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.button_w_icon i {
    padding: 0 16px;
}

.button_icon {
    font-size: 1.1rem;
    margin-right: 8px;
}
