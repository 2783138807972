.apply_gift_headline {
    display: flex;
    align-items: flex-end;
}

.apply_gift_headline h2 {
    margin: 16px 0 0 0 ;
}
    
    
    .choose_account {
        justify-content: space-between;
    }

    .choose_account .financial_text_container {
        margin-left: 8%;
    
    }

    .choose_account p{
        padding: 1.1875rem 0;
    }

.highlight {
    border: 2px #DF1648 solid;
}

.guide_prompt{
    margin: 0;
}

.recommendation_container {
    margin: auto 2px;
}

.recommendation_container p {
    margin: 4px auto;
}