.media_upload_wrapper{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;   
}

.media_uploader_sub_wrapper {
    flex-basis: 48%;
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.media_uploader_sub_wrapper img {
    width: 36px;
}

.media_upload_preview {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.media_upload_cancel {
    position: relative;
    right: 20px;
}

.media_upload_cancel .material-icons {
    padding: 4px;
    margin: 6px auto;
    background: #ffffff;
    border-radius: 100%;
}

.uploaded_media_preview {
    max-width: 100%;
    height: auto;
    max-height: 350px;
    align-self: flex-start;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.12);

    position: relative;
    left: 18px;
}

.greeting_image_container .uploaded_media_preview{
    left: 0px;
}

.upload_picture_button_selected {
    background: #B5F1DB;
}