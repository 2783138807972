/* see styles in the main App css for form inputs */

.terms_of_service_container {
    position: fixed;
    width: 90%;
    z-index: 10;
    bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media only screen and (min-width: 600px) {
    .terms_of_service_container {
        width: 50%;
    }
}