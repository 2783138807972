.rule_of_72_wrapper {
    margin: 16px;
}

.flex_box {
    display: flex;
    align-items: baseline;
}

.small_input {
    width: 110px;

    border: 1px solid rgba(0, 0, 0, 25%);
    border-radius: 5px;
    margin: 8px 0px 0px 0px;
    padding: 4px;
    box-sizing: border-box;
    resize: none;
    font: inherit;
    /* testing a new size font here. May need to revert back to 0.875rem if this looks to be too big */
    font-size: 1rem;
}

.investment_growth_list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* space between items */
}

.investment_growth_list > div {
    width: 45%; 
    
}

.grid-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px; /* Adjust this value as needed */
}
