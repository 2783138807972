.profile_overlay_menu {
    position: fixed;
    z-index: 23;
    right: 5%;
    top: 45px;
    
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;

}

.overlay_list{
    margin: 0;
    margin-top: 4px;
    width: 100%;
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box; 

}

.overlay_list_item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #ffffff;

    width: 100%;

    display: flex;
    align-items: center;
    box-sizing: border-box; 
    justify-content: space-between;
}

.overlay_list_item:last-child {
    border-bottom: none;
}

.overlay_no_link {
    width: 100%;
}

.overlay_list_item p {
    padding:  8px;
    padding-right: 24px;
    margin: 0;
}

.overlay_list_item a {
    width: 100%;
} 

.overlay_list_item p:hover {
    background-color: rgba(0,0,0,0.12);
}

/*
    .profile_overlay_menu p::before {
        right: 15px;
    }
*/

.arrow-up {
    width: 0; 
    height: 0; 
    border-left: 3.5px solid transparent;
    border-right: 3.5px solid transparent;
    border-bottom: 3.5px solid #ffffff;
    position: absolute;
    right: 10px;
    top: 1px;

  }
  .arrow-up-outline {
    width: 0; 
    height: 0; 
    border-left: 5.5px solid transparent;
    border-right: 5.5px solid transparent;
    border-bottom: 5px solid rgba(0,0,0,0.12);
    position: absolute;
    right: 8px;
  }

@media only screen and (min-width: 600px) {

    .profile_overlay_menu {
        right: 17.5%;
        top: 56px;
    }

    .arrow-up {
        right: 13px;
    }

    .arrow-up-outline {
        right: 11px;
    }
}



