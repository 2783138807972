.top_selector_container{
    background-color: #16DFAD;
    
    width: 100%;
    position: fixed;
    top: 45px;
    z-index: 1;

    display: flex;
    justify-content: center;
}

.top_secondary_container {
    display: flex;
    width: 80%;
}

.selector_item {
    flex-basis: 50%;
    text-align: center;
    border-bottom: 4.5px solid #00BC7A;
}

.unselected {
    border-bottom: none;
}

.top_selector_container .unselected {
    color: rgba(0, 0, 0, 0.54);
}
