
header.home_header {
    background-color: #16dfad;
    
    border-bottom: 1px solid rgba(0,0,0,0.12);

    width: 100%;
    position: fixed;
    top: 0%;
    z-index: 22;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px;

}
header .home_header_container {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.home_header_logo {
    background-color: transparent;
    width: 50px;
    height: auto;
    max-height: 65px;
}

.home_header_container * {
    flex-basis: 33%;
}

.home_header_container h2 {
    text-align: center;
}

.home_header_container .background_for_icon {
    text-align: right;
}

@media only screen and (min-width: 600px) {

    header .home_header_container {        
        width: 65%;

        height: 55px;
    }


}