

/* goal details */
.goal_details_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
}

.first_details_line {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 16px;
}

.last_details_line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-bottom: 4px;
}

.goal_details_line {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.goal_details_line p {
    margin: 0px 0px 12px 0px;
  }

.goal_details_heading {
    flex-basis: 55%;
    text-align: left;
}

.goal_details_data {
    flex-basis: 40%;
    color: #FFA700;
    text-align: right;
}