.loader, .loader:after {
    border-radius: 50%;
    width: 6em;
    height: 6em;
}



.loader {
    position: relative;
    margin: 60px auto;
    font-size: 10px;
    
    text-indent: -9999em;
    
    border-top: 1.1em solid rgba(255,215,0, 0.2);
    border-right: 1.1em solid rgba(255,215,0, 0.2);
    border-bottom: 1.1em solid rgba(255,215,0, 0.2);
    border-left: 1.1em solid #ffd700;

    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader.mini_loader {
  font-size: 4px;
  margin: 1rem auto;
}