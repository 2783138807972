
.category_container {
    min-width: 200px;
    flex-basis: 100%;
    margin: 10px 0;

}

.category_picture_container {
    border-radius: 20px 20px 0 0;
    /* temp until we add pictures or illustrations */
    height: 135px;
    background-color: rgba(0, 0, 0, 0.12);
    color: gray;
    align-items: center;
    text-align: center;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    border: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: none;
}

.category_picture_container_529 {
    background-image: url('../../../../../images/offer_category/529.jpg');
    background-position-y: -30px;
}

.category_picture_container_savings {
    background-image: url('../../../../../images/offer_category/savings.jpg');
}

.category_picture_container_investing {
    background-image: url('../../../../../images/offer_category/investing.jpg');
}

@media only screen and (min-width: 600px) {
    .category_picture_container_529 {
        background-position-y: -40px;
    }

    .category_picture_container {
        min-height: 155px;
    }
}


.category_headline_container {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0 0 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100px;
    background-color: white;

}

.category_headline {
    padding: 0px 8px;
}


@media only screen and (min-width: 600px) {
    .category_container {
        min-width: 300px;
        flex-basis: 30%;
        margin: 10px;
    }
}