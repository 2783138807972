

.gifting_page_picture {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: 50% 50%;
    align-self: flex-start;
    margin-bottom: 8px;
    margin-top: 8px;
    border-radius: 5px;
}

@media only screen and (min-width: 600px) {

    .gifting_page_picture {
        height: 420px;
    }
}

.registries_links_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}