
/*  overall wrapper  */
.click_button_wrapper{
    margin-bottom: 24px;
}

/*  recommendation line  */
.click_button_rec_container {
    justify-content: flex-start;
    display: flex;
}

.click_button_rec_container p{
    flex-basis: 47.5%;
    text-align: center;
    margin: 0;
    margin-bottom: 8px;
}

/*  the buttons container  */
.click_button_container{
    display: flex;
    justify-content: space-between;
    
}

.click_button_container_single{
    justify-content: center;
    
}

/*  Inside the button  */
.click_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    flex-basis: 47.5%;

    border: 2px solid rgba(0,0,0,0.12);
    border-radius: 5px;

    cursor: pointer;
}

.unavailable_click_button, .click_button.unavailable_click_button i  {
    background: #f4f4f4;
    color: rgba(0,0,0,0.25);
    cursor: not-allowed;
}

.click_button_selected {
    color: #16dfad;
    border: 2px solid #16dfad;
}

.click_button i {
    font-size: 3rem;
    margin: 8px;
}

.click_button p {
    padding: 8px;
    margin: 0px;
    text-align: center;
}

p.click_button_header {
    padding: 0px;
    margin: 0px;
    
}

.StripeElement{
    border: 2px solid rgba(0,0,0,0.12);
    border-radius: 5px;
    
    padding: 12px;
    margin: 24px 0;
}

.StripeElement--complete {
    border: 2px solid #16dfad;
    
}

.StripeElement--invalid {
    border: 2px solid #DF1648;
    
}

.stripe_cc_badge_wrapper {
    width: 100%;
    display: flex;
    margin: 48px 0;
    justify-content: center;
}

.stripe_cc_badge_wrapper img {
    max-width: 100%;
    height: auto;
}

.stripe_and_plaid_container {
    text-align: center;
}

.powered_by_stripe_container .stripe_and_plaid_container img {
    margin: 0 0 0 8px;
    max-height: 1.25rem;
}

.click_button_container img {
    margin: 8px auto;
    max-height: 1.5rem;
}

.powered_by_plaid {
    display: flex;
    align-items: center;
}