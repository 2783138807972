.tracker_container {
    text-align: center;
    display:flex;
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;

    flex-direction: column;
    align-items: center;
}

.circle_big {
    height: 22px;
    width: 22px;
    border-radius: 100%;
    background-color: #00BC7A;
}

.circle_small {
    height: 10px;
    width: 10px;
    margin-left: 12px;
    border-radius: 100%;
    background-color: #16DFAD;
}

.circles_line {
    background-color: #B5F1DB;
    width: 80%;
    max-width: 400px;
    display: flex;
    height: 5px;
    align-items: center;
    justify-content: space-between;
}

.circles_line_for_2 {
    width: 60%;
    max-width: 300px;
}

.tags_container {
    color: #00BC7A;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 515px;
}

.tags_container_for_2 {
    width: 78%;
    max-width: 380px;
}
p.tag{
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 0; 
}

.active_tag {
    font-weight: bold;
}