.offers_secondary_header {
    width: 100%;
    display: flex;
    justify-content: center;
    /*
    background-color: white;

    
    position: fixed;
    top: 3rem;
    z-index: 1;
    */
}

.offers_secondary_header_container {
    display: flex;
    width: 100%;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.advertiser_disclosure {
    cursor: pointer;
}