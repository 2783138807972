
/* see styles in the main App css for form inputs */

.plaid_button_clicker {
    width: 100%;
}

.powered_by_stripe_container{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.12);
    padding-bottom: 8px;
}

.powered_by_stripe_container img {
    margin: 0 0 0 8px;
    max-height: 2rem;
}