.main_receive_illustration {
    max-width: 55%;
    height: auto;
    left: 22.5%;
    position: relative;
    top: 0.5rem;
}

@media only screen and (min-width: 600px) {
    .main_receive_illustration {
        max-width: 35%;
        left: 32.5%;
    }

}