footer {
    position: fixed;
    z-index: 1;
    bottom: 0%;
    WIDTH:100%;
    left: 0;

    z-index: 10;

    background-color: white;
    color: rgba(0, 0, 0, 0.54);
    border-top: 1px solid rgba(0, 0, 0, 0.12);

    display: flex;
    justify-content: center;
}

nav {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
}

.nav_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px auto;
    cursor: pointer;
}

.nav_link {
    width: 20%;
    text-decoration: none;
}

.nav_item p {
    margin: 5px auto;
    color: rgba(0, 0, 0, 0.54);
}

p.selected {
    color: #16DFAD;
}


.nav_item {
    font-size: 1.125rem;
}

.material-icons-outlined.nav_icon, .material-icons.nav_icon  {
    font-size: 2rem;
}

.material-icons-outlined.nav_icon.selected, .material-icons.nav_icon.selected  {
    color: #16DFAD;
}


@media only screen and (min-width: 600px) {


    /* make the nav buttons more centered */
     nav {
        width: 80%;
     }
}