

/* see styles in the main App css for form inputs */
.gifting_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 24px auto;
    padding-top: 8px;
    border-top: 1px dotted rgba(0,0,0,0.12);
}

.gifting_footer img{
    max-height: 1.5rem;
}

.gifting_footer a, .gifting_footer p {
    margin: 0 4px;
}

@media only screen and (min-width: 600px) {
    .gifting_footer {
        width: 75%;
        
    }
}