.article {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    border-bottom: solid 1px #E5E5E5;

}

.article .text_and_headline_container {
    flex-basis: 70%;
}

.article .image_container {
    flex-basis: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.article .image_container img{
    width: 100%;
    max-height: 5rem;
    object-fit: scale-down;
}