.carousel_master_container {
    margin: 8px 0;
}

.carousel_slider_container {
    margin: 8px 0;
    overflow-x: auto;
    padding: 12px 0;
}


.carousel_slider_container {
    -webkit-scrollbar-width: none;
    -moz-scrollbar-width: none;
    -ms-scrollbar-width: none;
    -ms-overflow-style: none;  
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  
  .carousel_slider_container::-webkit-scrollbar {
  	display: none;
	}

.carousel_slider_content{
    display: flex;
}


.carousel_slide {
    width: 85%;
    
    margin: 0 8px;
    height: inherit;
}

.carousel_slide:first-of-type {
    margin-left: 0;
}

.carousel_slide:last-of-type {
    margin-right: 0;
}

.carousel_arrows_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel_arrow {
    padding: 8px 12px;
}
@media only screen and (min-width: 600px) {
    .carousel_arrow:hover {
        color: #16dfad;
    }
}