/* bar chart */
.goal_graphics_container {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bar_chart {
    width: 85%;
    display: flex;
    justify-content: center;
    margin: 16px 0;
}

.bar_chart p{
    margin: 0.25rem auto;
    height: 1rem;
}

.bar_one {
    background-color: #B5F1DB;
    border-radius: 5px 0 0 5px;
    display: block;
}

.bar_two {
    background-color: #16DFAD;
    display: block;
}

.bar_outline {
    background-color: rgba(0, 0, 0, 0.12);
    border-radius: 0 5px 5px 0;
    display: block;
}


.legend_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.legend_bar_one {
    width: 85%;
    display: flex;
    align-items: center;
}

.legend_bar_two {
    width: 85%;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.color_for_legend {
    width: 1rem;
    height: 1rem;
    margin: auto 0.5rem;
    border-radius: 5px;
}

.legend_bar_one .color_for_legend {
    background-color: #B5F1DB;
}

.legend_bar_two .color_for_legend {
    background-color: #16DFAD;
}

.text_for_legend p{
    margin: 0.25rem 0;
}