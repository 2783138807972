
.selector_field_master_container{
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    margin-top: 8px;
    background: #fff;
}

.selector_field_container {
    display: flex;
    
    align-items: center;
    justify-content: space-between;
}
.selector_field_container_tracker{
    text-align: center;
    margin: 4px auto;
}

.selector_field_container .material-icons-outlined{
    padding: 16px 8px;
}

.selector_field_container .material-icons-outlined.back_arrow{
    padding-right: 4px;
}

.selector_field_container .material-icons-outlined.forward_arrow{
    padding-left: 4px;
}


.selector_field_container textarea {
    border: none;
    margin-top: 0;
    padding: 4px;
    resize: none;
    height: 9rem;
}

textarea.selector_field_text_area {
    height: 7.75rem;
}

textarea.selector_field_text_area:focus, #selector_field_input:focus {
    outline: none;
}

.selector_field_char_count {
    text-align: right;
    margin-top: 4px;
    margin-right: 4px;
    color: rgba(0, 0, 0, 0.25)
}

.selector_field_randomizer_container {
    display: flex;
    justify-content: space-between;
}

.selector_field_randomizer_container p {
    margin: 0;
}

.selector_field_img_container {
    flex: 1;
    text-align: center;
    padding: 12px;
}