.goals_list_container{
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
}

.input_button_w_img{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    flex: 1 0 21%;

    margin: 6px 12px 6px 0px ;
    padding: 12px;
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 12%);
    /*
    color: rgba(0, 0, 0, 25%);
    */
    cursor: pointer;
    background: #ffffff;
    word-break: break-word;

}


.input_button_w_img:last-of-type{
    flex: 0 0 21%;
}

.input_button_w_img img{
    width: 60px;
}

.input_button_w_img p{
    text-align: center;
    margin-bottom: 0px;
}

@media only screen and (min-width: 600px) {
    .input_button_w_img:hover, .input_button_w_img.input_button_w_img_selected:hover{
        background: #daf5eb;
    }
    .input_button_w_img, 
    .input_button_w_img:last-of-type{
        flex: 0 0 18%;
    }
}

.input_button_w_img_selected{
    border: 2px solid #16dfad;
    color: #00BC7A;
    background: #B5F1DB;
}


.goals_list_container.goals_per_line_3 .input_button_w_img {
    width: calc(100% / 4 - 12px); /* 3 items per row for mobile */
    flex: none;

}

.goals_list_container.goals_per_line_3 .input_button_w_img:nth-child(3n) {
    margin-right: 0px;
  }
  

.goals_list_container.goals_per_line_4 .input_button_w_img{
    width: calc(100% / 5 - 12px); /* 3 items per row for mobile */
    flex: none;
}


.goals_list_container.goals_per_line_4 .input_button_w_img:nth-child(4n) {
    margin-right: 0px;
}

.goals_list_container.goals_per_line_3 .input_button_w_img.padding_4_mobile ,
.goals_list_container.goals_per_line_4 .input_button_w_img.padding_4_mobile {
    padding: 12px !important;
}