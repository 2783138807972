.greeting_box {
    
    padding: 8px;
    border-top: 5px dotted #FFD700;
    border-bottom: 5px dotted #FFD700;
    margin: 0px 0px 8px;
    text-align: center;
}

.greeting_box_green {
    border-top: 5px dotted #16dfad;
    border-bottom: 5px dotted #16dfad;
}

.greeting_box p {
    margin: 0.5rem;
    font-style: italic;
    
}

.header_for_greeting {
    margin: 0px 0px 12px 0px;
}

.received_picture_greeting{
    margin: 16px auto;
    max-width: 100%;
    max-height: 500px;
    border-radius: 5px;
}
#thank_you_link {
    border: 2px solid rgba(0,0,0,0.12);
    border-radius: 5px;
    margin: 16px 0;
}