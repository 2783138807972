.notifications-section {
    margin: 20px 0;
}

.notification-item-headline-and-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.notification-item {
    
    padding: 10px 0;
}

.notifications-section .notification-item:last-of-type {
    border-bottom: 1px solid #ccc;
}

.notification-item label {
    font-size: 16px;
}
