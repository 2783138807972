
/* footer */
footer.landing_footer {
    position: static;

    WIDTH: 100%;
    z-index: 10;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    display: block;
    
    justify-content: center;

}

.landing_footer_container {
    display: flex;
    width: 98%;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin: 16px 0;
    
}


.landing_footer_container .landing_page_button_sign_in {
    width: 12rem;
}



.landing_footer_menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-basis: 80%;
    margin: 24px auto;
}

.landing_footer_menu__item_header {
    padding-right: 4px;
    border-bottom: 2px solid #069f63;
    width: fit-content;
}

.landing_footer_menu_item {
    flex-basis: 40%;
}

.footer_item_container{
    flex-basis: 80%;
    text-align: center;
    cursor: pointer;
}

.logo_container.footer_item_container{
    flex-direction: column;
}

.footer_social_container {
    margin: 8px auto;
}

.footer_social_icon {
    padding: 4px;
}

@media only screen and (min-width: 600px) {
    
    .landing_footer_buttons, .footer_item_container {
        flex-basis: 12%;
    }

    .logo_container.footer_item_container {
        justify-content: center;
    }

    .landing_footer_menu {
        flex-basis: 60%;
    }

    .landing_footer_menu_item {
        flex-basis: 25%;
    }
}

.landing_footer_copyright {
    width: 95%;
    margin: 24px auto;
    padding: 16px 2px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}