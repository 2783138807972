.discover_header_buffer{
    background: #16dfad;
    height: 55px;
}

.discover_banner {
    background-image: url('../../../images/backgrounds/discover_background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    padding: 0 40px;
    height: 200px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.discover_main {
    top: -48px;
    width: 95%;
}

.discover_margin_wrapper{
    margin-right: 5%;
}


.discover_padding_wrapper{
    padding-right: 5%;
}

.discover_double_header_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.discover_double_header_wrapper h2{
    flex-basis: 75%;
}

@media only screen and (min-width: 600px) {
    .discover_banner{
        width: 55%;
        height: 350px;
        margin-left: 22.5%;
        border-radius: 5px;
        border: solid 1px rgba(0,0,0,0.12);
        box-sizing: border-box;
    }
    
    .discover_main {
        top: -80px;
        width: 50%;
    }

    .discover_header_buffer{
        margin-bottom: 16px;
    }
}
