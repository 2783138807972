.home_background{
    background-color: rgba(245,245,245);
}

.home_header_buffer{
    background: #16dfad;
    height: 55px;
    padding: 8px;
}

.home_banner {
    background-image: url('../../images/backgrounds/home_background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    padding: 0 40px;
    height: 200px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.home_main {
    top: -24px;
}

.home_item {
    margin: 8px auto;

    border: solid 1px rgba(0,0,0,0.12);
    border-radius: 5px;
    background-color: white;

    width: 100%;
}

@media only screen and (min-width: 600px) {
    .home_banner{
        width: 50%;
        height: 350px;
        margin-left: 25%;
        border-radius: 5px;
        border: solid 1px rgba(0,0,0,0.12);
        box-sizing: border-box;
    }
    
    .home_main {
        top: 12px;
    }

    .home_header_buffer{
        margin-bottom: 16px;
    }
}

/************ send and receive buttons *********************/
.send_and_receive_container {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}

.send_and_receive_button{
    display: flex;
    flex-direction: column;
    flex-basis: 50%;

    border: solid 1px rgba(0,0,0,0.12);
    border-radius: 5px;
    background-color: white;

    justify-content: center;
    align-items: center;

}

.send_and_receive_button img{
    width: 60px;
    margin-top: 12px;
}

.send_and_receive_button h2, .send_and_receive_button p{
    margin: 8px;
    text-align: center;
}

@media only screen and (min-width: 600px) {
    .send_and_receive_container {
        justify-content: space-between;
    }
    .send_and_receive_button {
        flex-basis: 48.5%;
    }
}

/************** discover articles  *******************/
.home_discover_articles {
    border-radius: 5px;
    border: solid 1px rgba(0,0,0,0.12);
    background-color: white;
}

.home_discover_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px rgba(0,0,0,0.12);
    padding: 0 8px;
}

.home_discover_articles_article {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
}

.home_discover_articles img {
    max-width: 50px;
    max-height: 50px;
    margin: 8px 8px 8px 0;
}

.home_discover_articles_article_left {
    display: flex;
    align-items: center;
}

/*************** discover offers ******************/
.home_discover_offer {
margin: 8px 0;
}

.home_item_header_container {
    padding: 0 5px;
}

.home_item_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.home_item_header p {
    margin-bottom: 8px;
}

.home_item_header_text p {
    margin: 0;
}

.home_discover_offer .carousel_slider_content{
    /* 
    align-items: center;
    */
}

.home_discover_offer .offer {
    background-color: white;
    margin: 8px 0;
    border-radius: 5px;
}

.carousel_slide .description {
    min-height: 220px;
}

.carousel_slide .logo_and_name_container {
    min-height: 5rem;
}

/**********************/

.home_item_with_image img {
    max-width: 135px;
    max-height: 100px;
    margin-top: 16px;
}

.home_item_with_image {
    display: flex;
    flex-direction: column;
    
    width: 100%;

    justify-content: center;
    align-items: center;

    margin: 8px 0;

    border: solid 1px rgba(0,0,0,0.12);
    border-radius: 5px;
    background-color: white;
}
/*
.home_plans_carousel .home_item_with_image {
    width: 85%;
}
*/

.carousel_slide .home_item_with_image {
    margin: 0;
    height: 99%;
}

.home_item_with_image p {
    margin: 8px 16px;
}

.home_item_with_image_text {
    text-align: center;
    margin-bottom: 8px;
}


.home_plans_carousel .kid_account{
    
    filter: none;
}

.carousel_slide .kid_name, .carousel_slide .kid_account {
    margin: 0;
}

.home_plans_carousel .kid_picture_container , .home_plans_carousel .plan_bottom{
    border-radius: 5px 5px 0 0;
}

.home_plans_carousel .plan_bottom{
    border-radius: 0 0 5px 5px;
}

/** recent sent gifts **/
.home_recent_gifts_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.home_recent_gifts_gift {

    margin: 8px 0;
    padding: 16px;
    padding-bottom: 0;
    padding-top: 8px;

    border: solid 1px rgba(0,0,0,0.12);
    border-radius: 5px;
    background-color: white;

    flex-basis: 100%;
}

@media only screen and (min-width: 600px) {
    .home_recent_gifts_gift{
        flex-basis: 44%;
    }
}

.home_recent_gifts_gift_cta {
    text-align: center;
    margin-top: -4px;
    margin-bottom: 12px;
}

/* additional items */
.home_additional_items_container, .home_social_container{
    border-radius: 5px;
    border: solid 1px rgba(0,0,0,0.12);
    background-color: white;
}

.home_additional_items_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px rgba(0,0,0,0.12);
    padding: 0 8px;
}

.home_additional_items_item:last-of-type {
    border-bottom: none;
}

.home_social_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin: 8px 0;
}

.home_social_icons_container{
    display: flex;
    margin: 8px;
}
.home_social_icons_container img {
    margin: 8px;
}

.home_social_container p {
    margin-top: 0;
    text-align: center;
}

.home_bottom_logo {
    width: 100%;
    text-align: center;
    padding-bottom: 16px;
}

.home_bottom_logo img {
    max-width: 250px;
}