.light_green_background {
    background-color: #B5F1DB;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    text-align: center;
}

.login_illustration_container{
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.light_green_background img {
    height: auto;
    max-height: 150px;
    max-width: 100%;
    margin-top: 5px;
}

img.login_logo {
    max-width: 80%;
    margin-top: 24px;
}

img.login_illustration{
    display: none;
    margin-top: 0;
}


@media only screen and (min-height: 700px) {
    .light_green_background img {
        max-height: 250px;
    }

    img.login_illustration{
        display: inline-block;
        margin-top: -24px;
    }
}


@media only screen and (min-height: 800px) {
    .light_green_background img {
        max-height: 350px;
    }
}


@media only screen and (min-width: 600px) {
    .light_green_background img {
        max-height: 325px;
    }


    img.login_illustration{
        display: inline-block;
        margin-top: -24px;
    }
}

@media only screen and (min-width: 1050px) {
    .light_green_background img {
        max-height: 345px;
    }
}



.login_container {
    width: 80%;
    left: 10%;
    bottom: 5%;
    position: fixed;
    background-color: white;
    text-align: center;
    border-radius: 5px;
}

@media only screen and (min-width: 600px) {
    .login_container {
        width: 35%;
        left: 32.5%;
    }
    
    img.login_logo {
        max-width: 50%;
    }

    .login_illustration_container{
        width: 35%;
        margin-left: 32.5%;
    }

}

.login_form_container {
    text-align: left;
}

   

.login_input p{
    text-align: left;
    margin-left: 10%;
    margin-bottom: 0.5rem;
    color: rgba(0, 0, 0, 0.54);
}

.login_username_input{
    padding-top: 4px;
}

#username_input, #password_input {
    border-radius: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.75);
    width: 80%;
    margin: 0;
}

.recaptcha_container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 16px 0;
}


.or p {
    margin: 0.5rem auto;
}

 


.additional_sign_in_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 10%;
}

.additional_sign_in_button {
    flex-basis: 33%;
    margin: auto;
    text-align: center;
}

.additional_sign_in_button p {
    margin: auto;
 }

.border_left_for_button {
    border-left: solid 1px rgba(0, 0, 0, 0.12);
}




/* */



button.signIn{
    
    
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0.5rem 1rem;
    margin: 0 auto;
    letter-spacing: 0.06rem;
    width: 80%;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    border: none;
    text-decoration: none;
    background: #FFD700;
    color: rgba(0, 0, 0, 0.75);
    font-family: 'Nunito', sans-serif;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
}

button:hover.signIn
{
    background: #FFA700;
}

button:focus.signIn {
    outline: 1px solid rgba(0, 0, 0, 0.12);
    outline-offset: -4px;
}

button:active.signIn {
    transform: scale(0.99);
}