.overlay_share_button {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 8px;
    cursor: pointer;
}

.overlay_share_button_container {
    margin: 8px 8px 16px 8px;
    display: flex;
    justify-content: space-around;
}

.main_plan_illustration_container {
    text-align: center;
}

.main_plan_illustration {
    max-width: 35%;
}

@media only screen and (min-width: 600px) {
    .main_plan_illustration {
        max-width: 45%;
    }
}