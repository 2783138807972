
.section_1.about{
    /*background-color: #B5F1DB;*/
    padding: 1rem 0 1rem 0;

    right: 0px;
    left: 0px;

    text-align: center;
    z-index: -1;
}

.about_button {
    background-image: url('../../../images/backgrounds/square_bottom_confetti.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
    min-height: 110px;
}

.single_column_content {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    padding: 0 10% 0 10%;
    margin-top: -10%;
}

.about_buffer {
    height: 10rem;
}

.background_confetti_image{
    background-image: url('../../../images/backgrounds/square_top_confetti.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom;
    min-height: 125px;
}

.about_founder_container{
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.about_founder_container h3{
    margin-top: 0;
}
.about_founder_img_container{
    text-align: center;
    
    flex-basis: 100%;
}
.about_founder_text_container{
    flex-basis: 100%;
}

.about_founder_container img {
    max-height: 125px;
    border-radius: 100%;
    border: 2px solid #6C7782;
}

.about_founder_container p{
    text-align: center;
}

@media only screen and (min-width: 600px) {
    .about_button {
        min-height: 335px;
    }


    .single_column_content {
        padding: 0 20% 0 15%;
    }


    .background_confetti_image{
        min-height: 225px;
    }


    .about_founder_img_container{
        text-align: center;
        flex-basis: 20%;
    }
    .about_founder_text_container{
        flex-basis: 75%;
    }
    
}