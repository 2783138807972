
.categories_container {
    display: flex;
    position: relative;
    /*top: 20px;*/
    flex-wrap: wrap;
    justify-content: center;
}

.main_offers_headline {
    text-transform: capitalize;
    text-align: center;
}
