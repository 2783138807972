
.interactive_greeting_master_container{
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    margin-top: 8px;
}

.interactive_greeting_container {
    display: flex;
    
    align-items: center;
}
.interactive_greeting_container_tracker{
    text-align: center;
    margin: 4px auto;
}

.interactive_greeting_container .material-icons-outlined{
    padding: 16px 8px;
}

.interactive_greeting_container .material-icons-outlined.back_arrow{
    padding-right: 4px;
}

.interactive_greeting_container .material-icons-outlined.forward_arrow{
    padding-left: 4px;
}


.interactive_greeting_container textarea {
    border: none;
    margin-top: 0;
    padding: 4px;
    resize: none;
    height: 9rem;
}

textarea.interactive_greeting_text_area {
    height: 7.75rem;
}

textarea.interactive_greeting_text_area:focus, #greeting_input:focus {
    outline: none;
}

.greeting_char_count {
    text-align: right;
    margin-top: 4px;
    margin-right: 4px;
    color: rgba(0, 0, 0, 0.25)
}