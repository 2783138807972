
/* CATEGORY LIST */

.pm_category_list {
    width: 100%;
    padding-inline-start: 0px;
    margin-block-start: 0px;
    margin: 16px 0;

    padding: 0 8px;
    
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-sizing: border-box;
}

.pm_category_item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    width: 100%;

    margin: 16px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box; 
    justify-content: space-between;
}

li:first-child.pm_category_item{
    margin-top: 0;
}

.pm_category_header {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.pm_category_header p{
    margin: 0;
}

.pm_category_header_main_text{
    flex-basis: 100%;
    margin: 0;    
}

.pm_category_header_sub_text {
    margin-right: 7%;
}

/* ITEM LIST */

.pm_item_list {
    width: 100%;
    padding-inline-start: 0px;
    margin-block-start: 0px;
    
    border: 1px  rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-sizing: border-box;

}

.pm_item {
    border-bottom: 1px  rgba(0, 0, 0, 0.12);

    margin-left: 5%;
    width: 100%;

    display: flex;
    align-items: center;
    box-sizing: border-box; 
    justify-content: space-between;
}

.pm_item_image_container {
    padding-right: 5%;
    flex-basis: 22%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.pm_item_image_container img {
    max-width: 100%;
    max-height: 3rem;
}

.pm_item_image_placeholder{
    padding: 0.8125rem 0;
    height: 2.2825rem;
    margin: 4.5px 0;
    display: block;
    border-bottom: 1px solid transparent;
}

.pm_item_text{
    flex-basis: 70%;
    
    padding-top: 4px;
    padding-bottom: 4px;
}

.pm_item_text p{
    padding: 0.8125rem 0;
    margin: 0;
    text-transform: capitalize;
}

.pm_item_icon {
    padding-left: 8%;
    flex-basis: 17%;
    text-align: center;
    display: flex;
}

/* added to add item separators */
.pm_item_right_side{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    width: 100%;

    display: flex;
    align-items: center;
    box-sizing: border-box; 
    justify-content: space-between;
}

li:last-child.pm_item .pm_item_right_side, li:last-child.pm_item .pm_item_image_container  {
    border-bottom: none;
}

/* verify button */

.pm_verify_button {
    padding: 4px 8px;
    background: #FFD700;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 5px;


}


.pm_verify_button:hover
 {
    background: #FFA700;
}

.pm_verify_button:focus {
    outline: 1px solid rgba(0, 0, 0, 0.12);
    outline-offset: -4px;
}

.pm_verify_button:active {
    transform: scale(0.99);
}

        
        

/*------------------------*/

