

/*  HEADER */

.ddc_header_text {
    flex-basis: 75%;
    margin-left: 5%;
    padding: 4px 0px;
}

.ddc_header_text p{
    text-transform: capitalize;
}

.ddc_header_arrow {
    flex-basis: 20%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dd-header-arrow img{
    width: 25px;
    height: 25px;
}


/* header with label */
p.ddc_header_label {
    padding: 0px;
    margin: 0px;
    margin-top: 0.57rem;
    margin-bottom: 0.3rem;
}

p.ddc_header_selected {
    padding: 0px;
    margin: 0px;
    margin-bottom: 0.57rem;
}

/* CATEGORY LIST */

.ddc_category_list {
    width: 100%;
    padding-inline-start: 0px;
    margin-block-start: 0px;
    
    border: 1px  rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-sizing: border-box;
}

.ddc_category_item {
    border: 2px solid rgba(0, 0, 0, 0.12);
    border-bottom: none;

    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box; 
    justify-content: space-between;
}

.ddc_category_disabled {
    cursor: not-allowed; 
    background:rgba(0, 0, 0, 0.06)
}

li:last-child.ddc_category_item {
    border: 2px solid rgba(0, 0, 0, 0.12);
}

.ddc_category_header {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.ddc_category_header p{
    margin: 0;
    margin-top: 8px;
}

.ddc_category_header_main_text{
    flex-basis: 52%;
    margin: 0;
    margin-left: 5%;
    
}

.ddc_category_header_sub_text {
    margin-right: 7%;
}

/* ITEM LIST */

.ddc_item_list {
    width: 100%;
    padding-inline-start: 0px;
    margin-block-start: 0px;
    
    border: 1px  rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-sizing: border-box;

}

.ddc_item {
    border-bottom: 1px  rgba(0, 0, 0, 0.12);

    width: 100%;

    display: flex;
    align-items: center;
    box-sizing: border-box; 
    justify-content: space-between;
}



.ddc_item:hover, .ddc_item:focus {
    background-color: #B5F1DB;
    
}

.ddc_category_disabled .ddc_item:hover{
    background-color: rgba(0, 0, 0, 0);

}

.ddc_item_image_container {
    margin-left: 5%;
    padding-right: 5%;
    flex-basis: 22%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ddc_item_image_container img {
    max-width: 100%;
    max-height: 3rem;
}

.ddc_item_image_placeholder{
    padding: 0.8125rem 0;
    height: 2.2825rem;
    margin: 4.5px 0;
    display: block;
    border-bottom: 1px solid transparent;
}

.ddc_item_text{
    flex-basis: 70%;
    
    padding-top: 4px;
    padding-bottom: 4px;
}

.ddc_item_text p{
    padding: 0.8125rem 0;
    margin: 0;
    text-transform: capitalize;
}

.ddc_item_icon {
    padding-left: 8%;
    flex-basis: 17%;
    text-align: center;
    display: flex;
}

/* added to add item separators */
.ddc_item_right_side{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    width: 100%;

    display: flex;
    align-items: center;
    box-sizing: border-box; 
    justify-content: space-between;
}

li:last-child.ddc_item .ddc_item_right_side, li:last-child.ddc_item .ddc_item_image_container  {
    border-bottom: none;
}

/* LAST ITEM */

.ddc_item_last {
    border: 2px solid rgba(0, 0, 0, 0.12);
    border-radius: 0 0 5px 5px;
}

.ddc_item.ddc_item_last .ddc_item_icon {
    flex-basis: 20%;
    display: block;
}

.ddc_category_item:last-child.rounded_bottom_last {
    border-radius: 0 0 5px 5px;
}


        
        

/*------------------------*/
