.offer {
    width: 100%;
    border: 1px solid rgba(0,0,0, 0.12);
    border-radius: 12px;
    margin: 24px auto;
}

.offer_inner_margin {
    margin: 1.5rem 1rem;
}

/* logo and name */

    .logo_and_name_container{
        display: flex;
        align-items: center;
    }

    .affiliate_logo_container {
        flex-basis: 40%;
        display: flex;
        /*justify-content: center;*/
        align-items: center;
        /*margin: 16px 1rem;*/
    }

    .affiliate_logo_img {
        max-width: 100%;
        max-height: 3rem;
    }

    .logo_and_name_container h2{
        flex-basis: 60%;
        text-align: center;
    }

.offer_expanded_info ul {
    padding-inline-start: 1rem;
}

.offer_expanded_info ul li {
    margin: 0.5rem 0;
}