.article_publish_date {
    margin: 0.5rem 0;
}

.article_header_cover_img_container, .article_section_img_container, .article_footer_logo_container{
    text-align: center;
}

.article_footer_logo_container{
    margin-top: 24px;
}

.article_header_cover_img{
    margin-top: 16px;
    max-width: 100%;
    max-height: 150px;
    background-size: contain;
    background: white;
}

.article_footer_logo{
    max-height: 100px;
    align-items: center;
    text-align: center;
}

.article_section_header{
    padding-top: 64px;
}

.article_section_cover_img {
    max-width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
    
    background: white;
}

.confetti_tile {
    background-image: url('../../../../images/backgrounds/Confetti_tile.png');
    /*background-image: url('../../../../images/offer_category/529.jpg');*/
    background-position: center;

}

.article_bio_container {
    margin: 24px 0 0 0;
}

.article_footer_container {
    margin-top: 24px;
    border-top: 1px solid rgba(0,0,0,0.12);
}

.article_up_next {
    margin-bottom: 0;
}

/*
.article_quote_container {
    display: flex;
}

.article_quote_open, .article_quote_close {
    font-size: 2rem;
    font-weight: bold;
    color: #16dfad;
    display: flex;
}

.article_quote_open {
    margin-right: 8px; 
    align-items: flex-start;
}

.article_quote_close {
    align-items: flex-end; 
}
*/




.article_body h2 {
    font-size: 1.25rem;
    margin-bottom: 6px;
}

.article_body h4{
    font-size: 1.125rem;
    margin-top: 40px;
    margin-bottom: 6px;
}

.article_body p {
    margin-top: 0;
    margin-bottom: 40px;
}

p.article_section_list_header {
    margin-bottom: 8px;
}


h2.article_quote, .article_quote {
    font-size: 2rem;
    border-bottom: 2px solid #16dfad;
    padding: 0.5rem 0;
    border-top: 2px solid #16dfad;
    margin: 0.5rem auto;
    font-weight: normal;
}

/* contents */

.article_contents_header {
    margin-bottom: 8px; 
}

.article_body ol, .article_body ul  {
    margin-top: 8px;
}