
.account_creation_buttons_container {
    display: flex;
    justify-content: space-between;  
    flex-wrap: wrap;  
}

.account_creation_button {
background-color: #B5F1DB;
flex-basis: 33%;
min-width: 105px;
margin: 0 auto 12px auto;
display: flex;
flex-direction: column;
align-items: center;
text-align: center;

border-radius: 5px;
border: solid 1px rgba(0, 0, 0, 0.25);
}

.account_creation_button img {
    height: 25px;
    width: 25px;
    border: rgba(0, 0, 0, 0.25);
    margin-top: 0.4375rem;
}

.account_creation_button p {
    margin-top: 0.4375rem;
}