
header {
    background-color: #16DFAD;
    
    width: 100%;
    position: fixed;
    top: 0%;
    z-index: 22;

    display: flex;
    align-items: center;
    justify-content: center;

}

header .header_container {    
    background-color: #16DFAD;

    height: 45px;
    width: 90%;


    display: flex;
    justify-content: space-between;
    align-items: center;
}

header h1 {
    margin: 0; 
}

header img {
    height: 25px;
    background-color: #00BC7A;
}

header .header_logo {
    background-color: transparent;
    display: flex;
}

.back_button_placeholder {
    background-color: #16DFAD;
}


.material-icons-outlined.person_icon {
    color: #00BC7A;
    font-size: 1.3rem;
    padding: 0.2rem;
    background-color: #B5F1DB;
    border-radius: 100%;
}


@media only screen and (min-width: 600px) {

    header .header_container {        
        width: 65%;

        height: 55px;
    }

}