.input_buttons_container{
    display: flex;
    flex-wrap: wrap;
}

.input_button, form input[type=text].input_button{
    margin: 8px 8px 0 0;
    padding: 4px 8px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 12%);
    color: rgba(0, 0, 0, 25%);
    cursor: pointer;
    width: auto;
    background: #ffffff;
}

@media only screen and (min-width: 600px) {
    .input_button:hover, form input[type=text].input_button:hover {
        background: #B5F1DB;
    }
}

.input_button_selected, form input[type=text].input_button_selected{
    border: 2px solid #16dfad;
    color: #16dfad;
    padding: 3.5px 7.5px;
}

.input_button.input_button_selected:hover, 
form input[type=text].input_button.input_button_selected:hover{
    background: transparent;
}

/**/
@media only screen and (max-width: 1300px) {
    .amount_input_button_container{
        max-width: 380px;
    }
}

.amount_input_button, form input[type=text].amount_input_button {
    width: 80px;
    text-align: center;
    box-sizing: content-box;
}

.gifting_flow_image_container{
    margin: 24px auto;
    text-align: center;
}

.gifting_flow_image_container img {
    max-height: 150px;
}