.two_column_flex_content.article_two_column {
    margin: 0;
}

.ask_for_gift_form {
    width: 100%;
}

.ask_for_buttons_container {
    width: 90%;
    margin-left: 5%;
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.ask_for_buttons_container_item {
    flex-basis: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.share_preview {
    width: 80%;
}

.share_preview h4 {
    border-bottom: 1px solid rgba(0,0,0,0.12);
}

.share_preview p{
    text-align: left;
}

.ask_for_buttons_container_item .overlay_share_button {
    border: solid 1px rgba(0,0,0,0.12);
    border-radius: 5px;
}

.ask_share_buttons {
    margin: 16px 0;
    border-top: 1px solid rgba(0,0,0,0.12); 
    width: 85%;
    margin-left: 7.5%;
}

@media only screen and (min-width: 600px) {
    .ask_share_buttons {
        width: 70%;
        margin-left: 15%;
    }
}

.button_with_icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button_with_icon .material-icons-outlined{
    padding: 0 8px;
}

.left_side .overlay form, form.ask_for_gift_form{
    margin-left: 0;
}

.ask_for_gift_alt_button {
    cursor: pointer;
}