.linked_account_headline {
    width: 100%;
    text-align: left;
}

.disclaimer_checkbox_container {
    display: flex;
    align-items: center;
    margin: 12px 0;
}

.disclaimer_checkbox {
    font-size: 1rem;
}

.disclaimer_checkbox_text {
    margin: 0 0 0 8px;
}

.greeting_image_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.greeting_image {
    max-width: 100%;
    max-height: 200px;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.no_border {
    border: none;
}
