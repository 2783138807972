.single_column_content.legal .landing_page_tagline {
    text-align: center;
    width: 100%;
}


.legal_selector_container    {
    width: 100%;
    
    display: flex;
    justify-content: center;

    margin-bottom: 24px;
}

.legal_selector_container_secondary {
    display: flex;
    width: 100%;
    justify-content: space-between;
    
}

.legal_selector_item {
    flex-basis: 47.5%;
    cursor: pointer;
    text-align: center;
}

.legal_selector_item h3 {
    margin-bottom: 0.5rem;
}

.legal_selector_item.legal_active {
    border-bottom: 5px solid #16dfad;
}


@media only screen and (min-width: 600px) {
    .legal_selector_container_secondary {
        width: 50%;
    }
}