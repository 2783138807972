.main_gift_img_container{
    margin: 24px 0;
    text-align: center;
}

.main_gift_illustration {
    max-width: 45%;
    height: auto;
    transform: rotate(-3deg);
}

@media only screen and (min-width: 600px) {
    .main_gift_illustration {
        max-width: 30%;
    }

}