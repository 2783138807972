.accordion_list_container {
    width: 90%;
    margin: 32px 5% 40px 5%;
    line-height: 1.6;
    
}

.accordion_list {
    width: 95%;
    margin: auto 2.5%;
    background: white;
    border: 1px solid rgba(0,0,0,0.12);
    filter: drop-shadow(8px 8px 0px #D8E1E9);
    cursor: pointer;
}


@media only screen and (min-width: 600px) {
    
    /* bigger shadow for full screen */    
    .accordion_list {
        width: 60%;
        margin: auto 20%;
        filter: drop-shadow(24px 24px 0px #D8E1E9);
    }
}

.accordion_item_container {
    border-bottom: 1px solid rgba(0,0,0,0.12);
}

/*
.accordion_item_container:hover {
    background: rgba(0,0,0,0.08)
}
*/

.accordion_item_container_open .accordion_item_header {
    background: rgba(0,0,0,0.08)
}

.accordion_item_container.accordion_item_container_open {
 /*   background: #B5F1DB;*/
}

.accordion_item_container:last-of-type {
    border-bottom: none;
}

.accordion_item_header, .accordion_item_content{
    display: flex;
    padding: 0 0 0 16px;
    justify-content: space-between;
    align-items: center;
}

.accordion_item_header h2 {
    width: 85%;
}

.accordion_icon, .accordion_item_content_placeholder{
    padding: 4px 8px;
    margin: 0 8px;
    
}

.accordion_item_content {
    display: flex;
    font-family: 'Nunito', sans-serif;
    color: rgba(0, 0, 0, 0.87);
}

 .accordion_content_container {
    margin-top: 16px;
    width: 85%;
}

.accordion_item_content p {
    margin-top: 0;
}

.accordion_item_content_placeholder{
    width: 24px;
    display: block;
}

.accordion_item_container_open .accordion_icon {
    transform: rotate(45deg);
}

.accordion_item_container_closed .accordion_item_content {
    display: none;
}