

/* Header */
.input-dd-wrapper .dd-header {
    border: 1px solid rgba(0,0,0,0.12);
    margin: 0;
}

.input-dd-wrapper .dd-header-text p {
    padding: 4px;
    color: rgba(0, 0, 0, 25%);
}

.input-dd-wrapper .dd-header-text {
    flex-basis: auto;
    margin-left: 0;
    padding: 0px;
}

.input-dd-wrapper .material-icons {
    font-size: 18px;
}

.input-dd-wrapper .dd-header-arrow{
    flex-basis: auto;
    margin-right: 8px;
    display: flex;
}

/* Selected Header */

.input-dd-wrapper .dd-header-selected .dd-header-text p {
    color: inherit;
}

.input-dd-wrapper .dd-header-selected {
    background: #ffffff;
    border: 2px solid #16dfad;
}

/* list */
.dd-list-wrapper-absolute {
    width: 100%;  
}

.dd-list-absolute {
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    z-index: 2;
    background: #ffffff;
    border: 1px solid rgba(0,0,0,0.12);

    width: 100%;
    padding-inline-start: 0px;
    margin-block-start: 0px;

    border-top: none;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-sizing: border-box;
}

/* list items */
.input-dd-wrapper .dd-list-item {
    justify-content: normal;
}

.input-dd-wrapper .dd-list-item-text {
    margin-left: 0;
    padding-left: 4px;
    flex-basis: auto;
    margin-right: 8px;
}

.input-dd-wrapper .dd-list-item-text p {
    margin: 0;
    padding: 8px 0;
}

.input-dd-wrapper .dd-list-item:last-of-type {
    border-bottom: none;
}

.input-dd-wrapper .dd-list-item-img {
    flex-basis: 10%;
}

.multi_dropdown_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.multi_dropdown_single{
    width: 100%;
    position: relative;
}

.multi_dropdown_single:first-of-type{
    padding-right: 8px;
}

.multi_dropdown_single .dd-list-absolute {
    width: calc(100% - 8px);
}