/*  */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.landing_body {
    font-family: 'Roboto', sans-serif;
}

/* HEADER */

header.landing_header {
    background-color: transparent;
    width: 100%;
    position: static;
    top: auto;
    z-index: 1;

}

.landing_header_container {
    display: flex;
    margin: auto 5%;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.logo_container {
    flex-basis: 33%;
    display: flex;
    margin: 8px 0px;
}

img.logo {
    width: 75%;
    height: auto;
    
    background-color: transparent;
    align-self: flex-start;
}


@media only screen and (max-width: 600px) {
    img.logo.logo_var_2 {
        width: 75%;
    }


    img.logo {
        width: 100%;
    }
}

.sign_in_container {
    flex-basis: 35%;
    align-content: center;
    display: flex;
    justify-content: center;
}


/* general - sections */
.section_header {
    width: 100%;
    text-align: center;
    
}

.section_header h2{
    font-size: 2rem;
    padding: 1rem 0 0 0;
    margin: 0 5%;
    margin-bottom: 1rem;
}

.section_button_container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
}


/* all sections */

.landing_main_section {
    padding: 16px 0;
}

/* Section 1 */

.section_1.cover {
    /*background-color: #B5F1DB;*/
    background-image: url('../../images/backgrounds/square_bottom.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom;

    padding: 1rem 0 1rem 0;

    right: 0px;
    left: 0px;

    text-align: center;
    z-index: -1;
}

@media only screen and (min-width: 600px) {
    .section_1.cover {
        background-size: 100% 100%;
    }
}


.section_1_buttons_container {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.landing_page_tagline {
    text-align: left;
}

.landing_page_tagline h1 {
    font-size: 2.5rem;
    margin: 0.5rem 0;
}

.landing_page_tagline h3 {
    color: rgba(0, 0, 0, 0.85);
}

.section_1 .left_side {
    justify-content: center;
}


@media only screen and (min-width: 600px) {
    .landing_page_tagline h2 {
        font-size: 3rem;
    }
}

/* section 2 */


.section_2, .section_3 , .section_7, #gift_of_financial_knowledge {
    background: #F9F9F9;
}


.section_2 .blocks_container h3 , .section_3 .blocks_container h3, .section_4 h3 {
    color: #00BC7A;
}


.section_2 .section_header h2{
    margin-bottom: 1rem;
}



/* section 3 */ 

/* section gift of knowledge */

.discover_questions_container {
    width: 90%;
}

.discover_question {
    background: #069F63;
    border-radius: 10px;
    width: 80%;
    filter: drop-shadow(12px 12px 0px #D8E1E9);
    
}

.discover_question_indented {
    margin-left: 20%;
}

.discover_question p {
    margin: 1.5rem 0;
    padding: 16px;
    color: white;
    
}

.gift_of_financial_knowledge_text {
    width: 90%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


@media only screen and (min-width: 600px) {    
    .discover_questions_container {
        width: 86%;
        border-radius: 3rem;
        margin-bottom: 1rem;
    }

    .gift_of_financial_knowledge_text {
        min-height: 22rem;
    }
}

/* section 4 */


/* section 5 - greeting*/


.section_5 {
    background-image: url('../../images/backgrounds/square_top.png');
    background-repeat: no-repeat;
    background-size: 200% 101%;
    background-position: bottom;
}

@media only screen and (min-width: 600px) {
    .section_5 {
        background-size: 100% 101%;
    }
}


.landing_form {
    width: 80%;
}

.section_5 .section_header h2 {
    margin-bottom: 1rem;
}

.section_5 .right_side img {
    margin: 1rem 0;
}

.section_5 .section_button_container {
    padding-top: 1rem;
}

/* section 6 security section */

.gg_security {
    background-image: url('../../images/backgrounds/square_reverse.png');
    background-repeat: no-repeat;
    background-size: 200% 100%;
    background-position: top;
}

.gg_security .section_header {
    display: flex;
    width: 80%;
    margin: 2rem 10%;
    flex-wrap: wrap;
    align-items: center;
}

.section_6_img_container {
    flex-basis: 100%;
}

.section_6_headers_text {
    flex-basis: 100%;
    margin: 32px 5% 0 5%;
}

@media only screen and (min-width: 600px) {
    .section_6_headers_text {
        flex-basis: 70%;
        margin: 0 5%;
    }

    .section_6_img_container {
        flex-basis: 20%;
    }

    .section_6_headers_text {
        margin: 0 5%;
    }
}

.gg_security .section_header h2{
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
    text-align: left;
}

.gg_security .section_header h3 {
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
}

.gg_security .block h3 {
    color: #5481C2;
}

.gg_security .blocks_container {
    margin: 0 10%;
}

/* blocks for sections */

.blocks_container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 1rem 0;
}

.block {
    flex-basis: 85%;
    min-width: 225px;
    margin: 12px;

    display: flex;
    flex-direction: column;
}

.section_2 .block{
    align-items: center;
}

.block h3 {
    width: 100%;
    margin: 0px;
    margin-bottom: 16px;

}

.block img {
    max-width: 65%;
    height: auto;
    margin-bottom: 24px;

}

@media only screen and (min-width: 600px) {
    .block {
        flex-basis: 25%;
    }
}

.block p{
margin: 8px 0px;
}





/* two column content for section */ 

.two_column_flex_content {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    margin: auto 5%;
}

 .left_side, .right_side {
    flex-basis: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}


.left_side img , .right_side img {
    max-width: 70%;
    height: auto;
}

@media only screen and (min-width: 600px) {

    .right_side, .left_side {
       flex-basis: 50%;
   }

   .left_side img , .right_side img {
       max-width: 70%; /* old was 75%*/
   }
}

.two_column_screenshot {
    margin: 24px;
}

.two_column_reverse {
    flex-wrap: wrap-reverse;
}

.two_column_screenshot .left_side, .two_column_screenshot .right_side {
    justify-content: center;
}


.two_column_screenshot .left_side img, .two_column_screenshot .right_side img{
    max-width: 60%;
    margin: 0 48px;
}

.two_column_screenshot_textbox {
    max-width: 350px;
    text-align: center;
}

#send_gifts h3{
    color: #069F63;
}

#receive_gifts h3{
    color: #FFA700;
}

@media only screen and (min-width: 600px) {
    .two_column_screenshot_textbox {
        text-align: left;
    }

    .two_column_screenshot .left_side {
        align-items: flex-end;
    }

    .two_column_screenshot .right_side {
        align-items: flex-start;
    }
}

/* sub-section */
.sub_section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sub_section_header {
    font-size: 1.5rem;
    padding: 1rem 0 0 0;
    margin: 0 5%;
    margin-bottom: 1rem;
}

.trust_icons_container {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;

    justify-content: space-evenly;

    margin: auto 5%;
    border-radius: 10px;
    background: white;
    padding: 16px;
    margin-bottom: 4rem;
}

.trust_icon_item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 40%;
    margin: 8px;
    min-height: 3rem;
}

.trust_icon_item img {
    max-width: 70%;
}

@media only screen and (min-width: 600px) {
    .trust_icon_item {
        flex-basis: 22.5%;
    }
}


/* general - buttons */


button.landing_page_button_sign_in, button.landing_page_button{
    
    
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0.5rem 0.5rem;
    margin: 0.5rem;
    letter-spacing: 0.06rem;

    border-radius: 0px;
    text-align: center;

    border: none;
    text-decoration: none;
    background: #069F63;
    color: white;
    font-family: 'Nunito', sans-serif;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
}

button.landing_page_button{
    width: 12rem;
}
button.landing_page_button_sign_in {
    width: 7rem;
}

button:hover.landing_page_button_sign_in, button:hover.landing_page_button
{
    background: #069F63;
}

button:focus.landing_page_button_sign_in, button:focus.landing_page_button {
    outline: 1px solid rgba(0, 0, 0, 0.12);
    outline-offset: -4px;
}

button:active.landing_page_button_sign_in, button:active.landing_page_button {
    transform: scale(0.99);
}

/* icons */


.material-icons-outlined.landing_page_icon, .material-icons-two-tone.landing_page_icon {
    /*

    color: #FFA700;
    background: #FFF9C0;
    */
    /*
    color: #FFF9C0;
    background: #FFA700;

    */

    color: #ffffff;
    background: #00BC7A;
    
    /*
    background: #B5F1DB;
    padding: 0.8rem;
    margin: 1rem 0;
    */

    font-size: 3rem;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    
    cursor: default;
}

.material-icons-outlined.landing_page_icon_blue {
    background: #5481C2;
}

.material-icons-outlined.landing_page_icon.with_gift {
    padding: 30px 0 0 0;
    background: none;
    color: #F6902E;
}

.gift_with_arrows {
    display: flex;
    justify-content: center;
    align-items: center;
}

