.picture_upload_wrapper{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 5px;
    margin-top: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    background: #fff;
}

.upload_picture_button {
    text-align: center;
    padding: 16px;
}

.uploaded_picture_preview {
    max-width: 100%;
    height: auto;
    /*
    max-height: 300px;
    */
    align-self: flex-start;
}

.uploaded_picture_preview_200-420 {
    width: 100%;
    /* height: 200px; */
    object-fit: cover;
    object-position: 50% 50%;
    align-self: flex-start;
    border-radius: 5px;
}

@media only screen and (min-width: 600px) {

    .uploaded_picture_preview_200-420 {
       /* height: 420px; */
    }
}

.slider_wrapper {
    padding: 16px 32px;
    position: relative;
    bottom: 52px;
}

.cropper_button_wrapper {
    position: relative;
    bottom: 64px;
}

.cropper_wrapper {
    position: relative;
    width: 100%;
    height: 300px;
}

@media only screen and (min-width: 600px) {
    .cropper_wrapper {
        height: 400px;
    }

    .cropper_button_wrapper {
        position: static;
        margin-top: 16px;
    }

    .slider_wrapper {
        position: static;
    }

}