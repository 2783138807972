footer.page_footer {
    position: static;
    z-index: 1;
    bottom: 0;
    WIDTH: 100%;
    left: 0;
    display: block;
    
    
    border-top: 1px solid #d8e1e9;
    background-color: white;
    color: #28313b;
    padding-top: 27px;
    padding-bottom: 27px;
}

.div-block-34 {
    margin-left: 5%;
    margin-right: 5%;
}

.footer_columns {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
}

.w-col {
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
}
.w-col-2 {
    width: 12.6667%;
}

.w-col-7 {
    width: 55.3333%;
}

.w-col-3 {
    width: 18%;
}

.footer-links {
    text-align: right;
    flex-wrap: wrap;
    order: 0;
    align-self: auto;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
}

.w-inline-block {
    max-width: 100%;
    display: inline-block;
}


.landing_footer_menu_item {
    flex-basis: 30%;
    margin-bottom: 32px;
    margin-right: 16px;
}

.div-block-17 {
    width: 100%;
    display: flex;
}

.footer-heading {
    border-bottom: 2px solid #00bc7a;
    text-align: left;
    margin-top: 0;
    margin-bottom: 16px;
    margin-right: 0;
    padding-right: 4px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    text-decoration: none;
    display: inline;
}

ul.w-list-unstyled, ol.w-list-unstyled {
    margin-top: 0;
    margin-bottom: 10px;
}

.w-list-unstyled {
    padding-left: 0;
    list-style: none;
}

.footer-link-2 {
    color: #28313b;
    text-align: left;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    display: block;
}

.footer-link-2:hover {
    color: #16DFAD;
}

.footer_column {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
}

.centered-div {
    justify-content: center;
    align-items: center;
    display: flex;
}

.footer-social {
    padding: 8px;
}


.w-container {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
}

.div-block-3 {
    border-top: 1px solid #d8e1e9;
    padding-top: 16px;
    padding-left: 8px;
}


@media screen and (max-width: 479px) {
    .div-block-34 {
        margin-left: auto;
        margin-right: auto;
    }

    footer.page_footer {
        padding-top: 12px;
    }

    .columns {
        flex-wrap: wrap;
    }

    .w-col {
        width: 100%;
    }

    .footer-logo{
        text-align: center;
        padding-top: 10px;
        padding-bottom: 20px;
        display: block;
    }


    .footer-links {
        flex-wrap: wrap;
        flex: 0 auto;
        justify-content: space-between;
    }

    .landing_footer_menu_item {
        flex: 0 40%;
        margin-right: auto;
    }

    .footer-heading {
        margin-top: 20px;
    }
    .w-container{
        padding: 0 16px;
    }

}

@media screen and (max-width: 991px) {
    .footer-section {
        padding-left: 10px;
        padding-right: 10px;
    }
}
