.open_body {
    font-family: 'Roboto', sans-serif;
    color: #28313B;
    background: #f9f9f9;
}

.open_main, .open_footer_wrapper {
    position: relative;
    top: 71px;
    background: #f9f9f9;
    
}
@media only screen and (min-width: 600px) {
    .open_main, .open_footer_wrapper {
        position: relative;
        top: 88px;    
    }
}



/* header */ 
header.open_header {
    background-color: #16dfad;
    
    border-bottom: 1px solid rgba(0,0,0,0.12);

    width: 100%;
    position: fixed;
    top: 0%;
    z-index: 22;

    display: flex;
    align-items: center;
    justify-content: center;
}

header .open_header_container {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 71px
}

.open_header_logo {
    background-color: transparent;
    
    height: auto;
    max-height: 65px;
}

.open_header_container div {
    flex-basis: 100%;
}

.open_header_container .button_container {
    text-align: right;
    justify-content: flex-end;
}


@media only screen and (min-width: 600px) {
    header.open_header {
        padding: 8px 0px;
    }

    header .open_header_container {        
        width: 65%;

        
    }


    .open_header_container div {
        flex-basis: 33%;
    }

}

/*  main  */

    /* gift card */
.gift_card_container {
    background: #B5F1DB;
    text-align: center;
    padding: 40px 0;
}

.gift_card_container h2 {
    margin: 0;
    font-size: 1.5rem;
    width: 75%;
    margin-left: 12.5%;
}

.gift_card {
    display: flex;
    flex-wrap: wrap-reverse;
    width: 90%;
    margin: 40px;
    margin-left: 5%;
    justify-content: center;
    filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.50))
}

.gift_card_left, .gift_card_right {
    background: white;
    flex-basis: 90%;
    margin: 1px;
    border: 1px solid #D8E1E9;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.gift_card_left {
    border-radius:  0 0 10px 10px;
    text-align: left;
}

.gift_card_right {
    border-radius: 10px 10px 0 0;
    justify-content: center;
    align-items: center;
}

.gift_card_left_text_box {
    margin: 24px;
}

.gift_card_button_wrapper {
    margin: 24px;
}


.gift_card_img {
    max-width: 80%;
    border-radius: 5px;
    max-height: 80%;
    /*border: 1px solid #D8E1E9;*/
}

@media only screen and (min-width: 600px) {
    .gift_card_container h2 {
        width: 50%;
        margin-left: 25%;
    }

    .gift_card {
        width: 70%;
        margin-left: 15%;
    }

    .gift_card_left, .gift_card_right {
        flex-basis: 49%;
    }

    .gift_card_left {
        border-radius: 10px 0 0 10px;
        flex-basis: 30%;
    }

    .gift_card_right {
        border-radius:  0 10px 10px 0;
    }
}


.open_received_picture_greeting{
    margin: 16px auto;
    max-width: 95%;
    max-height: 500px;
    border-radius: 10px;
}


/*
@media only screen and (min-width: 600px) {
    .main_receive_illustration {
    }

}
*/
.open_gift_explainer_section {
    background: #f9f9f9;
}

.open_carousel_wrapper {
    width: 90%;
    margin-left: 5%;
    padding: 40px 0;
    display: flex;
    overflow-x: auto;
}

.open_carousel_wrapper {
    -webkit-scrollbar-width: none;
    -moz-scrollbar-width: none;
    -ms-scrollbar-width: none;
    -ms-overflow-style: none;  
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  
  .open_carousel_wrapper::-webkit-scrollbar {
  	display: none;
	}

.review_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    flex-basis: 40%;
    /*height: 100%;*/
    min-width: 240px;
    
    margin: 0 16px;
    padding-right: 8px;
    padding-left: 8px;
    
    border: 1px solid #d8e1e9;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 8px 8px 0 0 #d8e1e9;
}

@media only screen and (min-width: 600px) {
    .review_container{
        min-width: 300px;
    }
}

.review{
    padding-right: 40px;
    padding-left: 40px;
    background-image: url('https://uploads-ssl.webflow.com/60cca303af56dec963670957/60db7867e3d6d9aac1798cdd_%E2%80%9D%20(1).png'), url('https://uploads-ssl.webflow.com/60cca303af56dec963670957/60db78654eb5eb91aa6e03e1_%E2%80%9C%20(1).png') ;
    background-position: 100% 100%,0 12px;
    background-size: 30px 30px,30px 30px;
    background-repeat: no-repeat,no-repeat;    
}

.featured_wrapper {
    display: flex;
    margin: 0 3% 32px;
    justify-content: center;
    flex-wrap: wrap;
}

.featured_logo_link {
    display: flex;
    min-height: 2rem;
    margin: 8px;
    justify-content: center;
    align-items: center;
    flex: 0 30%;
    max-width: 100%;
}

.featured_logo {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    max-height: 100%;
    margin-bottom: 0;
    -o-object-fit: contain;
    object-fit: contain;
}

.open_gift_faq_imgs {
    display: flex;
}

.open_gift_faq_imgs img{
    max-width: 200px;
    margin: 12px 12px 12px 0;
}

/*  footer   */ 



/* see styles in the main App css for form inputs */

.open_footer_wrapper {
    background: #f9f9f9;
    padding: 24px;
}

.open_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding-top: 12px;
    border-top: 1px dotted rgba(0,0,0,0.12);
    
}

.open_footer img{
    max-height: 1.5rem;
}

.open_footer a, .open_footer p {
    margin: 0 4px;
}

@media only screen and (min-width: 600px) {
    .open_footer {
        width: 75%;
        
    }
}