@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');


html {
  background-color: white;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  color: rgba(0, 0, 0, 0.87)
}


@media only screen and (min-width: 600px) {
    
  /* bigger font size for computer screens */
  html {
      font-size: 18.4px;
  }
}

/* General styles */

  a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
  }

  .cursor, .pointer {
      cursor: pointer;
  }

  .no_margin {
    margin: 0;
  }

  .no_bottom_margin {
    margin-bottom: 0;
  }

  .margin_bottom_40 {
    margin-bottom: 40px;
  }

  .margin_bottom {
    margin-bottom: 24px;
  }

  .margin_top_24 {
    margin-top: 24px !important;
  }
  
  
  @media only screen and (max-width: 600px) {
    
      .padding_4_mobile {
        padding-right: 4px !important;
        padding-left: 4px !important;
      }  
}

/* Text styles */

  .money {
      color: #FFA700;
      font-weight: bold;
      
  }

  .secondary {
    color: #FFD700;
  }

  .secondary_dark {
    color: #FFA700
  }

  .gg_red {
    color: #DF1648
  }

  .green_text, a.green_text, li a.green_text {
      color: #16DFAD;
  }

  .primary_dark {
    color: #00bc7a;
  }

  .gray {
    color: #bbbbbb;
  }

  .bold {
    font-weight: bold;
  }
  
  .italics {
    font-style: italic;
  }

  .xl_text, form input[type=text].large_text {
    font-size: 1.5rem;
  }

  .large_text, form input[type=text].large_text {
      font-size: 1.125rem;
  }

  .small_text {
    font-size: 0.875rem;
  }
  
  .tiny_text {
    font-size: 0.6875rem;
  }

  .align_center {
    text-align: center;
  }

  .align_right {
    text-align: right;
  }

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.125rem;
}

.underline_full {
  border-bottom: 1px solid #212121;
  padding-bottom: 12px;
}

.inline {
  display: inline;
}

/* commonly used containers */

main {
position: relative;
width: 90%;
left: 5%;
top: 45px;

/*color: rgba(0, 0, 0, 0.75);*/
display: flex;
flex-direction: column;
}

.buffer {
  height: 9rem;
}

.mini-buffer {
  height: 4rem;
}
/*
.blurred_background_2 {
  backdrop-filter: blur(1px);
  
  position: fixed;
  bottom: 170px;
  z-index: 5;

  width: 100%;
  height: 12px;
}
*/
.blurred_background_1 {
  backdrop-filter: blur(2px);
  
  position: fixed;
  bottom: 0px;
  z-index: 5;

  width: 100%;
  height: 10rem;
}

.shadow {
  filter: drop-shadow(2px 4px 6px black);
}

.rotate_90 {
  transform: rotate(90deg);
}

.rotate_270 {
  transform: rotate(270deg);
}

@media only screen and (min-width: 600px) {
  

  /* smaller width for main body */
  main {
      width: 50%;
      left: 25%;

      top: 55px;
  }
  /*  Push the blurred background up when the screen is bigger  */
  
}


  body {
      margin: 0px;
  }

/* Two column body type */

.two_column_text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.two_column_line {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.left_column_text {
  flex-basis: 40%;
  text-align: left;
}

.right_column_text {
  flex-basis: 40%;
  text-align: right;
}

.two_column_line p {
  margin: 0px 0px 12px 0px;
}

.two_column_line_new_section {
  padding-top: 12px;
  border-top: 1px solid rgba(0, 0, 0, 12%);
}


/*   Form input styles     */


form label {
  width: 100%;
  margin: 16px 0px 8px 0px;
} 

p.form_label {
  width: 100%;
  margin: 16px 0px 0px 0px;
}

p.form_label.tiny_text {
  margin: 8px 0px 0px 0px;
}

p.form_radio_label {
  display: inline-block;
  margin: 16px 24px 0px 8px;
}

.horizontal_radio_container {
  display: flex;
  flex-direction: row;
}

p.form_label.inline_label {
  display: inline;
}

form input[type=text], form textarea, form input[type=date], input[type=password], 
input[type=email], select, input.dropdown_input, datalist, option {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 12%);
  border-radius: 5px;
  margin: 8px 0px 0px 0px;
  padding: 4px;
  box-sizing: border-box;
  resize: none;
  font: inherit;
  /* testing a new size font here. May need to revert back to 0.875rem if this looks to be too big */
  font-size: 1rem;
}

form input[type=text]::placeholder, form textarea::placeholder, form input[type=date]::placeholder, input[type=password]::placeholder, 
input[type=email]::placeholder, select::placeholder, input.dropdown_input::placeholder, datalist::placeholder, option::placeholder {
  opacity: 1;
  color: rgba(0, 0, 0, 25%);
}

select {
  -webkit-appearance: none;
}

.landing_select_container{
  display: flex;
  align-items: flex-end;
  border: 1px solid rgba(0, 0, 0, 12%);
  border-radius: 5px;
  margin-top: 8px;
}

.landing_select_container select {
  margin: 0;
  border: none;
}
.landing_select_container select:active, .landing_select_container select:focus{
  outline: none;
}

.arrow_container_select{
  display: flex;
  align-items: center;
  padding: 4px;
  padding-right: 8px;
}

select:invalid {
  color: rgba(0, 0, 0, 25%);
}

input:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}

p.validation_message_error {
  margin: 4px 0px 0px 0px;
  color: #DF1648;
}

input.invlid_input, input.invlid_input:focus, input.invlid_input:active {
  border: 2px solid #DF1648;
}



textarea {
  height: 6.5rem;
}

/* icons */
.material-icons-outlined, .material-icons  {
  cursor: pointer;
}

.material-icons-outlined.green_icon, .material-icons.green_icon {
  color: #16DFAD;
}

.inline_icon {
  font-size: inherit;
  padding: 4px;
  margin: 0 4px;
}

.headline_with_icon {
  display: flex;
  align-items: end;
}

/* CARD COMPONENTS */

    .card_with_image_container img {
      max-width: 100px;
      max-height: 100px;
      margin-top: 16px;
    }

    .card_with_image_container {
      display: flex;
      flex-direction: column;
      
      width: 100%;

      justify-content: center;
      align-items: center;

      margin: 16px 0;

      border: solid 1px rgba(0,0,0,0.12);
      border-radius: 5px;
      background-color: white;
    }

    .card_with_image_text {
      text-align: center;
      margin-bottom: 8px;
    }
    .card_with_image_text p {
      margin: 8px 16px;
    }

/* toggle switch */
    .toggle_switch_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .toggle_switch_icon {
      margin-left: 6px;
      padding: 2px;
    }

/* calendar package */

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-popper{
  z-index: 11 !important;
}
.react-datepicker__calendar-icon {
  margin-top: 8px;
  cursor: pointer;
}

.react-datepicker__input-container.react-datepicker__view-calendar-icon input[type=text]{
  padding: 6px 10px 5px 34px !important;
}

.react-datepicker__day--outside-month {
  color: #bbbbbb !important;
} 


.react-datepicker__day--selected {
  background-color: #b5f1db !important;
  color: #00BC7A !important;
  font-weight: bold !important;
  /*
  background-color: #FFA700 !important;
*/
  border: 2px solid #00BC7A !important;
}

.react-datepicker__day--today {
  /*
  color: #00BC7A !important;
  */
  font-weight: bold !important;
}
/*
.react-datepicker__day--selected.react-datepicker__day--today {
  color: white !important;
  color: #00BC7A !important;
}
*/
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}


.mobile_only {
  display: block;
}
.desktop_only {
  display: none;
}

@media only screen and (min-width: 600px) {
  .mobile_only {
    display: none;
  }

  .desktop_only {
    display: block;
  }

}

.remove_icon {
  padding: 1px;
  background: white;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 54%);
  color:rgba(0, 0, 0, 54%);
}