/*
.gift {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    color: rgba(0, 0, 0, 0.84);
    margin-bottom: 16px;
}

.gift .l1 {
    margin-bottom: 8px;
    margin-top: 2px;
}
    
.gift .l2 {
    margin-top: 8px;
    margin-bottom: 16px;
}

.gift span {
    float: right;
}
*/

.gift {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    color: rgba(0, 0, 0, 0.84);
    margin-bottom: 16px;
    
    display: flex;
    justify-content: space-between;

}

.gift .l1 {
    margin-bottom: 8px;
    margin-top: 2px;
}
    
.gift .l2 {
    margin-top: 8px;
    margin-bottom: 16px;
}

.gift span {
    float: right;
}

.gift_left_side {
    flex-basis: 75%;
}


.gift_right_side {
    flex-basis: 20%;
    text-align: right;
}
