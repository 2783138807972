.popup_blur_all {
    backdrop-filter: blur(2px);
    background:rgba(0,0,0, 0.12);
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 12;
  
    width: 100%;
    height: 100%;
}

.overlay {
    position: fixed;
    max-height: 77.5%;
    overflow-y: scroll;
    top: 5rem;
    z-index: 12;

    width: 80%;
    left: 10%;

    text-align: center;

    background-color: #ffffff;
    /*border: solid 2px rgba(0,0,0, 0.3);*/
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.25));
    border-radius: 12px;
}

.overlay.wide_overlay {
    width: 90%;
    left: 5%;
}

    .overlay_headline_container{
        text-align: center;
    }

    .overlay_icon_and_headline_container {
        display: flex;
        flex: row;
        justify-content: space-between;
        align-items: center;
        border-bottom:1px solid rgba(0,0,0, 0.12);
    }

        .overlay_icon_and_headline_container .material-icons{
            margin-left: 8px;
        }

        .overlay_icon_and_headline_container_placeholder{
            width: 32px;
            height: 10px;
        }

    .overlay_text_container {
        width: 90%;
        margin-left: 5%;
    }



    .overlay_buttons_container_flex {
        width: 90%;
        margin-left: 5%;
        margin-top: 3rem;
    }


@media only screen and (min-width: 600px) {
    .overlay {
        width: 40%;
        left: 30%;
    }

    .overlay.wide_overlay {
        width: 60%;
        left: 20%;
    }
}
.overlay_popup_img_container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.overlay_popup_img {
    max-width: 75%;
    max-height: 20rem;
}

.overlay form{
    width: 90%;
    margin-left: 5%;
}

.overlay form p{
    text-align: left;
}