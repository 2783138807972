    
.primary_guide_container  {
    display: block;
    width: 75%;
    margin-left: 12.5%;
}

.guide_arrow_container {
    position: relative;
    display: block;
    bottom: 13px;
}

.arrow_up_outline {
    width: 0; 
    height: 0; 
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid rgba(0,0,0, 0.3);
    position: absolute;
    left: 12px;
  }

.arrow_up {
    width: 0; 
    height: 0; 
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 13px solid #FFF9C0;
    position: absolute;
    top: 3px;
    left: 13px;
  }

.secondary_guide_container {
    border: solid 2px rgba(0,0,0, 0.3);
    border-radius: 5px;
    background: #FFF9C0;
}