
.plan_bottom {
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 0 0 5px 5px;
    background-color: white;
    padding: 0 8px;
}


.plan_bottom_buttons_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   
    align-items: center;
    height: 100%;
    
    border-bottom: 1px solid rgba(0,0,0,0.12);
}

.plan_bottom_buttons_row:last-child {
    border-bottom:  none rgba(0,0,0,0.12);
}

.plan_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.left_button {
    flex-basis: 70%;
}

.right_button {
    flex-basis: 20%;
    justify-content: flex-end;
}

.plan_bottom img {
    width: 25px;
    height: 25px;
}

.share_button {
    margin-right: 8px;
}



