.kid_picture_container {
    height: 150px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.12);
    border-radius: 5px 5px 0 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.kid_name {
    padding: 20px;
    position: relative;
    color: white;
    font-size: 1.5rem;
    
    margin: 0;
}

.plan_menu_button {
    padding: 8px;
    background: #fff;
    border-radius: 100%;
    margin-right: 8px;
}