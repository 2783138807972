.dd-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin: 8px 0px;
    cursor: pointer;
}

/*  HEADER */
.dd-header {
    border: 2px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;

    background: white;
    width: 100%;

    display: flex;
    align-items: center;
    margin: 8px auto;
    box-sizing: border-box; 
    justify-content: space-between;
}

.dd-header-inactive {
    opacity: 0.65;
    background: rgba(239, 239, 239, 0.3);
    
    cursor: not-allowed;
}

.dd-header-selected {
    background-color: #B5F1DB;
}

.dd-header-open {
    margin-bottom: 0;
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.dd-header-text {
    flex-basis: 75%;
    margin-left: 5%;
    padding: 4px 0px;
}

.highlight {
    border: 2px red solid;
}

.dd-header-text p {
    padding: 1.1875rem 0;
    margin: 0px;
}

.dd-header-arrow {
    flex-basis: 20%;
    text-align: center;
}

.dd-header-arrow img{
    width: 25px;
    height: 25px;
}

/* LIST */
.dd-list {
    width: 100%;
    padding-inline-start: 0px;
    margin-block-start: 0px;
    
    border: 2px solid rgba(0, 0, 0, 0.12);
    border-top: 2px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-sizing: border-box;

}

.dd-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    width: 100%;

    display: flex;
    align-items: center;
    box-sizing: border-box; 
    justify-content: space-between;
}

.dd-list-item-last {
    border-bottom: none;
}

.dd-list-item:hover, .dd-list-item:focus {
    background-color: #B5F1DB;
}

/*  list items  */
.dd-list-item-text {

    flex-basis: 52%;
    margin-left: 5%;
}

.dd-list-item-secondary-text {
    flex-basis: 20%;
}

.dd-list-item-img {
    flex-basis: 20%;
    text-align: center;
}

.dd-list-item-img img{
    width: 25px;
    height: 25px;
}

/*  list items WITH IMAGES  */
.dd-list-item-image_container {
    margin-left: 5%;
    flex-basis: 15%;
    text-align: center;
    display: flex;
}

.dd-list-item-image_container img {
    max-width: 80%;
    max-height: 3rem;
}

.dd-list-item-text_with_img{
    flex-basis: 45%;
    margin-left: 12%;
}
/*
.dd-list-item-secondary-text_with_img {
    flex-basis: 12%;
}
*/
.dd-list-item-img_with_img {
    flex-basis: 20%;
    text-align: center;
}
